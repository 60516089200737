<template>
  <v-app id="main-app">
<span class="d-flex justify-end align-center ma-6">
      <span style="width: 8%">
        <v-select
            class=""
            v-model="languageSelect"
            :items="langs"
            item-text="name"
            item-value="id"
            menu-props="auto"
        >
          <template #prepend>
            <div class="mt-n1">
            <country-flag :country=" languageSelect === 'en' ? 'usa' : 'esp' "/>
            </div>
          </template>
        </v-select>
      </span>
    </span>
    <v-container class="fill-height d-flex justify-center flex-column" fluid>
     <span class="d-flex justify-center align-center my-0">
          <img
              :src="logo"
          />
          <h2 style="margin-left: 10px; font-weight: 400;">{{$t('product-name')}}</h2>
        </span>
      <v-card color="white" class="mx-auto rounded mt-6" width="400px">
        <v-card-title class="justify-center pt-5"><img v-bind:src="logo.src" v-bind:style="{ width: logo.width, height: logo.height }"></v-card-title>
        <v-card-subtitle>
          <v-row
              class="font-bold text-center my-0 d-flex justify-center align-center main_black--text title-login"
              style="font-size: 20px !important;"
          >
            {{ $t('partOfWellnessMetric') }}
          </v-row>
        </v-card-subtitle>
        <v-card-subtitle class="text-center primary--text title-login py-0" style="font-size: 16px; !important">{{$t('deletedAccount')}}</v-card-subtitle>
      </v-card>
      <div class="mt-5">
        <v-row dense>
          <v-col class="text-center  main_black--text ma-0 pa-0" cols="12">
              <span style="font-size: 14px; opacity: 0.7">
                {{ $t('signing-in-to-this-webapp') }}
              </span>
            <span
                style="font-size: 14px; cursor: pointer;"
                class="primary2--text"
                @click="redirectToWebsiteTerms"
            >
                {{ $t('website-terms-of-use') }}
              </span>
          </v-col>
        </v-row>
        <v-row dense class="ma-0 pa-0 ">
          <v-col class="text-center  main_black--text mt-0 pt-0" cols="12">
              <span style="font-size: 14px; opacity: 0.7">
                {{ $t('acknowledge') }}
              </span>
            <span
                class="primary2--text"
                style="font-size: 14px; cursor: pointer;"
                @click="redirectToPrivacyTerms"
            >
                {{ $t('privacy-policy') }}
              </span>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-app>
</template>

<script>

import { logoLogin } from '@/assets';

export default {
  components: {
  },
  data () {
    return {
      logo: logoLogin,
      languageSelect: 'en',
      langs: [
        { id: 'en', name: 'EN' },
        { id: 'es', name: 'ES' },
      ],
    };
  },
  computed: {
  },
  watch: {
    languageSelect (val) {
      this.$cookies.set('language', val, '6m');
      this.$i18n.locale = this.$cookies.get('language');
    },
  },
  mounted () {
    this.$i18n.locale = 'en';
  },
  methods: {
    language (val) {
      this.$cookies.set('language', val, '6m');
      this.$i18n.locale = this.$cookies.get('language');
    },
    redirectToWebsiteTerms () {
      window.open(
          'https://www.wellnessmetric.net/wellnessmetric_website_terms_of_use.html',
          '_blank',
      );
    },
    redirectToPrivacyTerms () {
      window.open(
          'https://www.wellnessmetric.net/wellnessmetric_website_privacy_policy.html',
          '_blank',
      );
    },
  },
};
</script>
<style scoped>
#main-app {
  background: #F9F8FB;
}
</style>
